import { registerMicroApps, runAfterFirstMounted, setDefaultMountApp, start, initGlobalState, addGlobalUncaughtErrorHandler } from 'qiankun';
import { map, tap } from 'rxjs/operators';
import { removeAppPath, APP_SEPARATOR } from '../util/utils';
import PortalServiceClient from './client/portal-service-client';

const appCallbacks = {
    beforeLoad: [
        app => {
            console.log('[LifeCycle] before load %c%s', 'color: green;', app.name);
        },
    ],
    afterMount : [
        app => {
            console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name);
        },
    ],
    beforeMount: [
        app => {
            console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name);
        },
    ],
    afterUnmount: [
        app => {
            console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name);
        },
    ],
};

class ApplicationRegistry {

    constructor(portalServiceApiUrl, accessToken) { 
       this.portalServiceClient = new PortalServiceClient(portalServiceApiUrl, accessToken);
    }

    activeRuleMatcher = (path) => (location) => {

        const realLocation = removeAppPath(location.pathname)
        const match = realLocation == path;

        if(match) console.log("[Path Matcher] matched path" , realLocation);

        return match;
    };

    registerApps$ = (appProps) => {
        return this.portalServiceClient.getPortalStructure$()
            .pipe(
                map(({apps, pages}) => this.buildApps(apps, pages, appProps)),
                tap(apps => console.log("[ Application Registry ] apps", apps)),
                tap(apps => registerMicroApps(apps, appCallbacks))
            );
    }

    buildApps = (apps, pages, appProps) => {
        return apps.map(
            app => Object.assign( {
                    props : Object.assign({
                        basePath : app.path + APP_SEPARATOR + "/",
                        pages: pages,
                        appPath: app.entry
                    }, appProps),
                    loader: appProps.loader,
                    activeRule : this.activeRuleMatcher(app.path)
              }, app) 
        )
    }

    getPortalStructureForLanguage$ = (language) => {
        return this.portalServiceClient.getPortalStructureForLanguage$(
            language
        );
    }

    existsAppPath() {

    }
}

export default ApplicationRegistry; 