import { pluck, shareReplay, tap, map } from 'rxjs/operators';
import _ from "lodash";
import Axios from "axios-observable";
import { of } from 'rxjs';

class PortalServiceClient {

    constructor(servicePath, accessToken) {
        this.accessToken = accessToken;
        this.servicePath = servicePath;

        const getPortalStructure$ = this.readPortalStructure$();
        
        this.portalStructure$ = getPortalStructure$.pipe(
            map(pages => { 
                return{
                    apps: this.mapPagesToMicroApps(pages),
                    pages:pages
                }
        }))
    }

    readPortalStructure$ = (language) => {
        const getPortalStructure$ = Axios.get( 
            this.servicePath + '/v1/portal', 
            { headers: { 
                'Authorization': 'Bearer ' + this.accessToken,
                'i18n_language': language || 'it_IT'
            }}
        ).pipe(
            pluck("data"),
            shareReplay()
        );

        return getPortalStructure$;
    }

    // readPortalStructure$ = (language) => {
    //     const data = [{
    //         "id": "5fa536cf4ff93b2f46fcc286",
    //         "pageCode": "home",
    //         "title": "Home",
    //         "path": "/portal/home",
    //         "apps": [{
    //             "mountPoint": "footer",
    //             "component": {
    //                 "id": "5fa535514ff93b2f46fcc285",
    //                 "name": "footer-home",
    //                 "path": "http://footer-web-test-mef.portale-dag-dev-ocp-clu-5dcfffff1b985a5d4ffa5c0e00ef38cf-0000.eu-de.containers.appdomain.cloud/ng-footer-web/index.html",
    //                 "description": "FooterApp Portale DAG"
    //             },
    //             "id": "footer-web-home"
    //         }],
    //         "category": {
    //             "cod": "1",
    //             "description": "Categoria"
    //         },
    //         "permission": {
    //             "allowedRoles": []
    //         }
    //     }];

    //     return of(data).pipe(shareReplay());

    // }

    getPortalStructureForLanguage$ = (language) => {
        console.log("[PortalServiceClient] getting structure for language", language);
        return this.readPortalStructure$(language).pipe(
            tap(structure => console.log("[PortalServiceClient] got structure", structure))
        );
    }

    getPortalStructure$ = () => {
        return this.portalStructure$;
    }

    mapPagesToMicroApps = (pages) => {
        
        return pages.flatMap(page => 
             page.apps.map(app => {
                return {
                    name: app.id,
                    entry: app.component.path,
                    container: app.mountPoint,
                    path: page.path,
                }
            })
        )
    } 
}

export default PortalServiceClient; 