const AUTH_TOKEN_KEY = "ACCESS_TOKEN";
class CookieStore {

    getAccessToken() {
        const accessToken = this.getCookies()[AUTH_TOKEN_KEY];
        console.log("accessToken", accessToken);
        return accessToken;
    }

    getCookies() {
        const cookies = document.cookie.split("; ")
            .map(c => c.split("="))
            .reduce((acc, y) => { acc[y[0]] = y[1]; return acc; }, {});

        console.log("cookies", cookies);
        return cookies;
    }
};

const TokenStore = new CookieStore();

export default TokenStore;
