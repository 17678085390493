import { forkJoin, of, Subject } from "rxjs";
import portalNavigation from "../service/portal-navigation";
import ApplicationRegistry from '../service/application-registry';
import { tap, switchMap, map, catchError} from 'rxjs/operators';
import ToShellMessageHandler from '../service/to-shell-message-handler';
import { hasAppPath, removeAppPath, withAppPath } from '../util/utils';
import renderPageNotFound from '../render/ReactErrorPageRenderer';
import renderSpinner from '../render/ReactSpinnerRenderer';
import { SpinnerService } from '../service/spinner-service';
import { addGlobalUncaughtErrorHandler, runAfterFirstMounted, setDefaultMountApp, start } from "qiankun";
import I18nStore from "../service/i18n-store";
import renderErrorPage from "../render/ReactErrorPageRenderer";

class Portal {

    constructor(configIn) {

        this.applicationRegistry = new ApplicationRegistry(
            configIn.portalServiceUrl,
            configIn.portalAccessToken
        );

        this.toShellMessageBus = new Subject();
        this.i18nStore = new I18nStore(configIn.defaultLanguage);

        this.messageHandler = new ToShellMessageHandler(this.toShellMessageBus, this.i18nStore);

        this.portalConfigContributors$ = [
            of((appConfig) => Object.assign({bus: this.toShellMessageBus},appConfig)),

            of((appConfig) => Object.assign({
                    language: this.i18nStore.getLanguage$(),
                    page$: this.i18nStore.getLanguage$()
                        .pipe(switchMap(this.applicationRegistry.getPortalStructureForLanguage$))
                },
                appConfig)
            )
        ];

        this.config = {
            appConfig: configIn.appConfig,
            templateRenderer: configIn.templateRenderer,
            beforeLoad: configIn.beforeLoad,
            pageNotFoundPath: configIn.pageNotFoundPath,
            appConfigContributors$: configIn.appConfigContributors$ || []
        };

        addGlobalUncaughtErrorHandler( error => {
            console.log('[Portal] Global Error', error);
        });

        runAfterFirstMounted(() => {
            console.log('[Portal] first app mounted');
        });
    }

    redirectOnMissingAppPath() {
        if(!hasAppPath(window.location.pathname)) {
            const pathWithAppSeparator = withAppPath(window.location.pathname);
            portalNavigation.navigate(pathWithAppSeparator);
        }
    }

    bootstrap$ = () => {

        renderSpinner();
        this.config.templateRenderer();

        SpinnerService.push();

        //normalizzazione path al caricamento
        this.redirectOnMissingAppPath();

        this.messageHandler.beginListening();

        const renderPortal$ = (appConfig, error) =>
            this.applicationRegistry.registerApps$(appConfig).pipe(
                tap(apps => {

                    const basePath = removeAppPath(window.location.pathname);

                    //verifica esistenza pagina
                    const existsAppForPath = (apps || [])
                        .map(app => app.props.basePath)
                        .map(removeAppPath)
                        .filter(path => path == basePath);

                    //gestione Pagina non trovata
                    if(error) {
                        renderErrorPage(error);
                        setDefaultMountApp(withAppPath("/error"));
                    }
                    else if(existsAppForPath && existsAppForPath.length > 0 ) {
                        setDefaultMountApp(window.location.pathname);
                    }
                    else {
                        renderErrorPage("Pagina non trovata");
                        setDefaultMountApp(withAppPath("/notFound"));
                    }
                }),
                tap(_ => start(
                    { singular: false
                    , sandbox: true
                    , prefetch: false
                    }
                    )
                )
            );

        if(this.config.beforeLoad) {
            this.config.beforeLoad();
        }

        const allConfigContributors = [
            ...this.config.appConfigContributors$,
            ...this.portalConfigContributors$
        ];

        return forkJoin(allConfigContributors)
            .pipe(
                map(appConfigReducers => {
                    return appConfigReducers.reduce((config, reducer) => {
                           return reducer(config);
                    },
                    this.config.appConfig);
                }),
                catchError(error => renderPortal$(this.config.appConfig, error) ),
                switchMap(appConfig => renderPortal$(appConfig)),
                SpinnerService.popOperator()
            );
    }
}

export default Portal;