import React from 'react';
import ReactDOM from 'react-dom';

function PageNotFound(props) {

  return (
    <>
      <header id="header"></header>
      <div id="main">
          <p> 
            {props.message}
          </p>
      </div>
      <div id="footer"></div>
    </>
  );
}

export default function renderErrorPage(errorMessage) {
  const container = document.getElementById('portal');
  ReactDOM.render(<PageNotFound message={errorMessage}/>, container);
}
