class Env {

    constructor() {
        this.serviceBaseUrl$ = window['portalServiceBaseUrl'];
        this.profileServiceBaseUrl$ = window['profileServiceBaseUrl'];
    }

    getServiceBaseUrl$ = () => {
        return this.serviceBaseUrl$;
    }

    getProfileServiceBaseUrl$ = () => {
        return this.profileServiceBaseUrl$;
    }
}

const env = new Env();

export default env;