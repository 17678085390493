import React from 'react';
import ReactDOM from 'react-dom';
import renderHTML from 'react-render-html';
 
function Render(props) {
  const htmlTemplate  = props.htmlTemplate;

  return (
    <>
      {renderHTML(htmlTemplate)}
    </>
  );
}

export default function renderTemplate() {
  const container = document.getElementById('portal');

  const htmlTemplate =  `<header id="header"></header>
                          <main id="main" ></main>
                          <footer id="footer"></footer>`;
  //renderHTML
  ReactDOM.render(<Render htmlTemplate={htmlTemplate} />, container);
}
