import { withAppPath } from "../util/utils";
import render from "../render/ReactTemplateRender";

class PortalNavigation {

    constructor() { }

    navigate = (path) => {
        const pathWithAppPath = withAppPath(path);
        render();
        history.pushState(null, pathWithAppPath, pathWithAppPath);
    } 
}

const portalNavigation = new PortalNavigation();

export default portalNavigation; 