import Axios from "axios-observable";
import { pluck } from "rxjs/operators";
import TokenStore from "../stores/auth-token-store";

class UserProfileService  {

    constructor(path, tokenService) {
        this.path = path;
        this.tokenService = tokenService;
    }

    login$() {
        return Axios.get(
            this.path + "/whoami", 
            { headers: { 'Authorization': 'Bearer ' + this.tokenService.getAccessToken() }
        }).pipe(
            pluck("data")
        );
    }
}

export { UserProfileService }
