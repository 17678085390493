import { Subject } from "rxjs"
import { scan, shareReplay, delay, finalize } from "rxjs/operators";

const spinnerSubject = new Subject();

const SpinnerService = {
    subject: spinnerSubject.pipe(
        scan((acc, value) => acc + value, 0),
        shareReplay()
    ),

    push: () => spinnerSubject.next(+1),
    pop: () => spinnerSubject.next(-1),

    popOperator: () => {
        return finalize(_ => SpinnerService.pop());
    }
}

export { SpinnerService }
