const APP_SEPARATOR = "/-";

function withAppPath(path) {
    const normalizedPath = path.endsWith("/") ? path.slice(0, -1) : path;

    return hasAppPath(normalizedPath) ? normalizedPath : normalizedPath + APP_SEPARATOR;
}
function hasAppPath(path) {
    const indexOfSeparator = path.indexOf(APP_SEPARATOR);

    return indexOfSeparator > -1;
}

function removeAppPath(path) {

    //const path = location.pathname;
    const indexOfSeparator = path.indexOf(APP_SEPARATOR);

    const realLocation = hasAppPath(path)
        ?  path.substring(0, indexOfSeparator) 
        : path;

    return realLocation;
}

function stipTrailingSlash(str){
    if(str.charAt(str.length-1) == "/"){ str = str.substr(0, str.length - 1);}
    return str
 }

 export {APP_SEPARATOR, stipTrailingSlash, removeAppPath, hasAppPath, withAppPath}