import { BehaviorSubject } from "rxjs";

const I18N_KEY = "I18N";

class I18nStore {

    constructor(defaultLanguage) {
        if(!sessionStorage.getItem(I18N_KEY)) {
            sessionStorage.setItem(I18N_KEY, defaultLanguage);
        }
        this.language$ = new BehaviorSubject(sessionStorage.getItem(I18N_KEY));
    }

    getLanguage$() {
        return this.language$;
    }

    nextLanguage(language) {
        sessionStorage.setItem(I18N_KEY, language);
        this.language$.next(language)
    }
}

export default I18nStore;
