import './index.less';

// for angular subapp
import 'zone.js/dist/zone';

import env from './env';
import Portal from './portal/portal';
import TokenService from './fit/service/token-service';
import render from './render/ReactTemplateRender';
import { UserProfileService } from './fit/service/user-profile-service';
import { map, tap } from "rxjs/operators";

const servicePaths = window["servicePaths"];
const missingTokenRedirectUrl = window["missingTokenRedirectUrl"];

const portaleServiceUrl = servicePaths["portale-fit-ms"];
const loginServiceUrl = servicePaths["AUTHORIZATION_MS_URL"];

const loader = loading => { };

const tokenService = new TokenService();

const token = tokenService.getAccessToken();

const userProfileService = new UserProfileService(loginServiceUrl, tokenService);

const USER_DETAILS_EXPIRATION_DAYS = 1;

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

if(!token) {
  window.location = missingTokenRedirectUrl;
}
else {

  const  userProfileContributor$ = userProfileService.login$()
    .pipe(
      tap(userData => {
        setCookie("USER_DETAILS", btoa(JSON.stringify(userData)), USER_DETAILS_EXPIRATION_DAYS);
      }),
      map(userData => (appConfig) => Object.assign({userProfile: userData }, appConfig) )
    );

  const appConfig = {
    loader: loader,
    serviceUrl: env.getServiceBaseUrl$(),
    jwtToken : token,
    servicePaths : servicePaths
  };

  const portal = new Portal({
    portalAccessToken: token,
    portalServiceUrl : portaleServiceUrl,
    appConfig: appConfig,
    defaultLanguage: "it_IT",
    templateRenderer: render,
    pageNotFoundPath: "/novares/notFound",
    appConfigContributors$: [
      userProfileContributor$
    ]
  });

  portal.bootstrap$().subscribe();
}

