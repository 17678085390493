import portalNavigation from "./portal-navigation"
import { SpinnerService } from "./spinner-service";
import { tap } from "rxjs/operators";
import { EventType } from "portal-shell-app-api";
import { runAfterFirstMounted } from "qiankun";

class ToShellMessageHandler {

    constructor(bus, i18nStore) {

        this.i18nStore = i18nStore;

        this.bus = bus.pipe(
			tap(message => console.log("Bus", message)),
            tap(message => this.handleMessage(message))
        );
        window.bus = bus;
    }

    handleMessage(message) {
        /*
            se il tipo di messaggio è "navigazione" -> esegui navigazione
            se il tipo di messaggio è "cambia lingua" -> propaga cambiamento della lingua
        */
        console.log("Recieved message", message);

        switch (message.type) {
            case EventType.NAVIGATE:
                portalNavigation.navigate(message.payload)
                break;
            case EventType.LOADING:
                message.payload > 0 ?
                    SpinnerService.push() :
                    SpinnerService.pop()
                break;
            case EventType.LANGUAGE:
                this.i18nStore.nextLanguage(message.payload);
            default:
                break;
        }
    }

    beginListening() {
        if(this.bus) {
            this.busSubscription = this.bus.subscribe()
         }
         else {
            console.error("[ToShellMessageHandler] failed to begin listening, no available subject")
         }
    }

    dispose() {
        if(this.busSubscription) {
            this.busSubscription.unsubscribe();
        }
        else {
            console.error("[ToShellMessageHandler] failed to unsubsribe, no available subscription")
        }
    }
}

export default ToShellMessageHandler;