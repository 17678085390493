import React, { useState, useEffect } from 'react';
import { SpinnerService } from '../service/spinner-service';
import ReactDOM from 'react-dom';

function Spinner() {

    const [visible, setVisible] = useState(false);

    useEffect(() => {
            
        const subscription = SpinnerService.subject
            .subscribe(opsLeft => setVisible(opsLeft > 0))

        return () => {
            subscription.unsubscribe()
        };
    }, [])

    return (
        <>
            {visible ? 
                <div className="overlay-loader">
                    <div className="spinner-loader">
                    </div>
                </div>
                : null
            }
        </>
    );
    
}

export default function renderSpinner() {
    const container = document.getElementById('spinner');
    ReactDOM.render(<Spinner />, container);
}
